<!-- 申报申请  用于渲染政策表单 -->
<template>
  <div>
    <!--    <headtitle Otitle="申报申请" />-->
    <el-card shadow="never">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>申报申请</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <h2 class="policy-title">{{ policyDeclare.title }}</h2>

      <div class="policy-time">
        开始时间:
        <span class="mr-3">{{ policyDeclare.createTime | formatDate }}</span>
        结束时间: <span>{{ policyDeclare.expireTime | formatDate }}</span>
      </div>

      <el-form
        :inline="true"
        label-position="top"
        label-width="80px"
        class="ruleForm"
        v-loading="loading"
        element-loading-text="拼命上传"
        style="height:auto;min-height:auto;padding-bottom:40px;"
      >
        <span
          v-for="(item, key) in fromData"
          :key="key"
          style="display:inline-block"
        >
          <el-form-item
            :label="item.label"
            v-if="item.type === 'text' || item.type === 'textarea'"
          >
            <el-input
              :type="item.type"
              :class="item.type"
              v-model="item.value"
            ></el-input>
          </el-form-item>
          <el-form-item :label="item.label" v-if="item.type === 'radio'">
            <el-radio-group v-model="item.value" size="medium">
              <el-radio-button
                v-for="(list, i) in item.options"
                :key="i"
                :label="list.name"
                >{{ list.name }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="item.label" v-if="item.type === 'select'">
            <el-select v-model="item.value" placeholder="请选择">
              <el-option
                v-for="list in item.options"
                :key="list.value"
                :label="list.label"
                :value="list.value"
                >{{ list.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item :label="item.label" v-if="item.type === 'checkbox'">
            <el-checkbox-group v-model="item.value">
              <el-checkbox-button
                v-for="(items, index) in item.options"
                :label="items.value"
                :key="index"
                >{{ items.name }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="item.label" v-if="item.type === 'date'">
            <el-date-picker
              value-format="yyyy-MM-dd hh:mm:ss"
              v-model="item.value"
              :type="item.type"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </span>
      </el-form>

      <el-form
        :inline="true"
        label-position="top"
        label-width="80px"
        class="ruleForm file-style"
        v-loading="loading"
        element-loading-text="拼命上传"
        style="height:auto;min-height:auto;padding-bottom:40px;"
      >
        <span
          v-for="(item, key) in fromData"
          :key="key"
          style="display:inline-block"
        >
          <el-form-item v-if="item.type === 'file'" :class="item.type">
            <div>
              <el-upload
                @click.native="setlabel(item.label, item.name)"
                :class="
                  'upload-demo' + parseInt(item.name.split('talentUpload')[1])
                "
                :action="host + '/file'"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="importHeaders"
                accept=".png,.jpg"
                :on-exceed="handleExceed"
                :limit="3"
                :file-list="
                  fileList[parseInt(item.name.split('talentUpload')[1]) - 1]
                "
                :on-success="handleSuccess"
                :on-progress="Progress"
              >
                <div>{{ item.label }}</div>
                <el-button
                  size="small"
                  type="primary"
                  @click="setlabel(item.label, item.name)"
                  >点击上传</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip "
                  style="color:red;margin-bottom: 32px"
                >
                  文件大小不超过500kb
                </div>
              </el-upload>
            </div>
          </el-form-item>
        </span>
      </el-form>
      <el-tooltip
        class="item"
        effect="dark"
        content="暂存功能不会存储上传的附件"
        placement="bottom"
      >
        <el-button class="submit" @click="requestDraft">暂存为草稿箱</el-button>
      </el-tooltip>
      <el-button type="primary" class="submit text" @click="Submit"
        >确认提交</el-button
      >
    </el-card>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
//import headtitle from "@/components/ControlPanel/headtitle";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      importHeaders: { token: sessionStorage.getItem("Token") },
      loading: false,
      filelabel: "",
      filekey: "",
      host: configDate.url,
      id: this.$route.params.id,
      fromData: [],
      policyDeclare: {},
      finalData: {
        formId: 0,
        policyId: this.$route.params.id,
        userId: sessionStorage.getItem("ID"),
        values: [],
      },
      temporaryList: [],
      fileList: [],
      newForm: {},
      newValue: false,
      draftId: false,
      updateDraft: {
        id: "",
        values: [],
      },
      policyId: "",
    };
  },
  created() {
    if (this.$route.params.id.split("&")[0]) {
      this.id = this.$route.params.id.split("&")[0];
    }
    this.updateDraft.id = this.$route.params.id.split("&")[1];
    this.finalData.policyId = this.$route.params.id.split("&")[0];

    this.getDate(); //初始化数据
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    getDate() {
      var self = this;
      //查询政策及动态表单
      this.$axios
        .get("/policy", {
          params: {
            id: self.id,
          },
        })
        .then(function(res) {
          if (res.data.status === 200) {
            self.policyDeclare = res.data.data;
            self.policyId = res.data.data.id;
            let DateOld = res.data.data.form.inputs;
            for (const key in DateOld) {
              if (DateOld[key].type === "checkbox") {
                DateOld[key].value = [];
              } else {
                //DateOld[key].value = "";
              }
              self.fromData.push(DateOld[key]);
            }
            self.finalData.formId = res.data.data.form.id;
            self.$axios
              .get("/affair/drafts?userId=" + self.finalData.userId)
              .then((response) => {
                if (response.data.status === 200) {
                  for (let i = 0; i < response.data.data.records.length; i++) {
                    if (
                      self.policyId === response.data.data.records[i].policyId
                    ) {
                      self.updateDraft.id = response.data.data.records[i].id;
                      self.draftId = true;
                      self.lintsID = response.data.data.records[i].id;
                      self.getDraft(self.lintsID);
                      return false;
                    }
                  }
                } else if (
                  response.data.data ===
                  "affair draft [1] by [page] not exists."
                ) {
                  self.getTalent();
                } else {
                  this.$root.warn("请刷新！");
                }
              });
          } else {
            self.$message({
              showClose: true,
              message: "该政策已被删除",
              type: "error",
            });
            self.$router.push("/declarelist");
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    getDraft(id) {
      const self = this;
      let newData = self.fromData;
      this.$axios
        .get("/enterprise", {
          params: {
            id: self.finalData.userId,
          },
        })
        .then(function(res) {
          if (res.data.data.status !== 21) {
            self.$message({
              showClose: true,
              message: "您的账号还没有认证通过！",
              type: "warning",
            });
            self.$router.push("/qyprocessing");
          } else {
            self.$axios
              .get("/affair/draft?affairDraftId=" + id)
              .then(function(res) {
                if (res.data.status === 200) {
                  self.draftId = true;
                  for (let i = 0; i < res.data.data.values.length; i++) {
                    newData.forEach((items) => {
                      if (items.name === res.data.data.values[i].name) {
                        if (items.name === "sex") {
                          items.value = res.data.data.values[i].value;
                        } else {
                          items.value = res.data.data.values[i].value;
                        }
                      }
                    });
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },

    requestDraft() {
      let self = this;
      var map = new Map();
      let objData = [];
      for (const key in this.fromData) {
        if (this.fromData[key].type !== "file") {
          map.set(this.fromData[key].name, this.fromData[key].value);
        }
      }
      for (let [k, v] of map) {
        objData.push({
          name: k,
          value: v,
        });
      }
      self.finalData.values = self.finalData.values.concat(objData);
      self.updateDraft.values = self.finalData.values.concat(objData);
      if (self.draftId === true) {
        self.$axios.put("/affair/draft", self.updateDraft).then((response) => {
          if (response.data.status === 200) {
            self.$root.success("更新成功!");
            self.getDraft(self.lintsID);
            self.$router.replace({
              path: "/declarelist",
              query: { open: 1, draft: true },
            });
          } else {
            self.$root.warn("更新失败！");
          }
        });
      } else {
        self.$axios.post("/affair/draft", self.finalData).then((response) => {
          if (response.data.status === 200) {
            self.$root.success("存储成功!");
            self.updateDraft.id = response.data.data;
            self.lintsID = response.data.data;
            self.getDraft(self.lintsID);
            self.$router.push("/declarelist?open=1");
          } else {
            self.$root.warn("存储失败！");
          }
        });
      }
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    Progress() {},
    setlabel(lebalname, v) {
      console.log(v);
      this.filekey = v;
      this.filelabel = lebalname;
    },

    handleSuccess(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      var self = this;
      if (response.status === 200) {
        let data = {
          id: response.data.id,
          memo: this.filelabel,
          filekey: this.filekey,
        };

        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            console.log(data);
            self.temporaryList.push(data);
            // self.fileList.push({
            //     name: self.filelabel,
            //     id: response.data.id
            // });
            self.$message({
              showClose: true,
              message: "上传成功",
              type: "success",
            });
          }
        });
      } else {
        this.loading = false;
        this.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    Submit() {
      this.$confirm("提交后不可再修改，确定要提交吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var self = this;
          var map = new Map();
          let objData = [];
          let objData2 = [];
          for (const key in this.fromData) {
            if (this.fromData[key].type !== "upload") {
              map.set(this.fromData[key].name, this.fromData[key].value);
            }
          }
          for (let [k, v] of map) {
            objData.push({
              name: k,
              value: v,
            });
            objData2.push({
              name: k,
              value: v,
            });
          }
          let delArr = [];
          let currentFileIndex = 0;
          for (let i = 0; i < objData.length; i++) {
            if (objData[i].name.indexOf("talentUpload") >= 0) {
              delArr.push(i);
              if (!self.temporaryList[currentFileIndex]) {
                self.$message({
                  showClose: true,
                  message: "请填写内容、上传文件",
                  type: "warning",
                });
                return false;
              }
              ++currentFileIndex;
            }
          }
          delArr.forEach((i) => {
            delete objData2[i];
          });
          let arr = [];
          self.temporaryList.forEach((item) => {
            arr.push({
              name: item.filekey,
              value: item.id,
            });
          });
          this.$nextTick(() => {
            self.finalData.values = arr.concat(objData2);
            self.finalData.values = self.finalData.values.filter((n) => n);
            let id = sessionStorage.getItem("draftId");
            self.$axios
              .post("/affair", self.finalData)
              .then(function(res) {
                if (res.data.status === 200) {
                  self.$message({
                    showClose: true,
                    message: "申报成功",
                    type: "success",
                  });
                  self.$axios
                    .delete("/affair/draft/whole?affairDraftId=" + id)
                    .then((response) => {});
                  self.$router.push("/meshenb");
                } else {
                  self.$message({
                    showClose: true,
                    message: res.data.data,
                    type: "warning",
                  });
                }
              })
              .catch(function(error) {
                console.error(error);
              });
          });
        })
        .catch(() => {});
    },
    beforeRemove(file, fileList) {
      let id = file.id || file.response.data.id;
      let newfileId = [];
      if (this.temporaryList.length) {
        this.temporaryList.forEach((item) => {
          console.log(item);
          if (item.id != id) {
            console.log(item);
            newfileId.push(item);
          }
        });
        console.log(newfileId);
        this.temporaryList = newfileId;
      }
      this.$axios.delete("/file/delete?id=" + id).then((response) => {});
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
};
</script>

<style lang="less" scoped>
.file-style {
  // margin-top: -96px;
}

.ruleForm {
  width: 1200px;
  text-align: left;

  min-height: 76vh;

  .el-form-item {
    margin: 0 5px;
    width: 390px;

    .el-form-item__content {
      padding: 0;
    }
  }

  .text {
    width: 344px;
    margin: 0 15px 0 0;
  }

  .textarea {
    width: 1200px;
  }

  .upload {
    display: block;
  }
}

.text {
  color: #ffffff;
}

.submit {
  width: 150px;
}

.policy-title {
  color: #128fec;
  text-align: left;
  margin: 0 0 12px 8px;
}

.policy-time {
  text-align: left;
  margin-left: 8px;
  margin-bottom: 16px;
}
</style>
